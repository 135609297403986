.authDialog{
    border: 2px solid;
    resize: both;
    overflow: auto;
    min-width: 350px;
    min-height: 575px;
}

.authDialog * {
    border-radius: 0pt!important;
}

.fakeA {
    color: #0d6efd !important;
    text-decoration: underline !important;
    cursor: pointer;
}

.authControl .form-floating {
    margin-bottom: 12px;
}


.ZviamapColors *{
    background-color: rgb(58, 142, 135);
    color: white;
}
