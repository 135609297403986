@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


:root {
	color: #403119;
	box-sizing: border-box;
	--viamap-green: #3A8E87;
	--viamap-dark: #334B49;
	--viamap-light: #96b1AD;
}
*, *:before, *:after {
	box-sizing: inherit;
}

html, body, h1, h2, p, ul, ol, li, input {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
}

a[href*="dummy"] {
  display: none !important;
}

body {
	/* height: calc(var(--vh, 1vh) * 100);	 */
	/* overflow: hidden; */
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#mit-searchbar-wrapper {
	position: relative;
  font-size: 1.3em;
}

#dawa-autocomplete-input {
	border: 3px solid var(--viamap-light);
	transition: border 0.3s linear;
}

#dawa-autocomplete-input:hover {
  border: solid var(--viamap-green) 3px;
}
#dawa-autocomplete-input:focus,
#dawa-autocomplete-input:active {
  border: solid var(--viamap-green) 3px;
}
.clear_btn {
  left: unset !important;
  right: 2% !important;
}
.SB2_Btn {
  transition: color 0.3s linear, opacity 0.3s linear;
  cursor: pointer;
  font-size: 1.1em;
  color: var(--viamap-green);
  border: 0;
  background: none;
  left: 2%;
  position: absolute;
  top: 20% !important;
}

.SB2_Btn svg {
	font-size: 145%;
}

.SB2_Btn:hover {
	color:#FA9B2E;
}



.SortButtons {
	width: 160px;
	display:flex;
	flex-direction: row;
	justify-content: space-around;
	z-index: 501;
	transition: top ease-out 200ms;
}

.SortButtons div{
	position: absolute;
	left: 0;
	height: 20px;
	line-height: 20px;
	cursor: pointer;
	opacity: 0;
	user-select: none;
	pointer-events: none;
}

.ActiveSort {
	opacity: 1 !important;
	pointer-events: all !important;
	color: var(--viamap-dark);
	text-decoration: underline;
	transition: color 0.2s linear, opacity 0.2s linear;
}

.ActiveSort:hover {
	opacity: 0.5 !important;
}

.addressfield {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: auto;
}

.dawa-wrap .addressfield input {
	font-size: inherit;
	padding: 10px;
	margin: 3px 0;
	width: 100%;
}

.GreenBar {
	height: 75px;
	padding: 0px 5%;
	display: flex;
	justify-content: space-between;
	align-content: center;
	align-items: center;
}

.contactLink:link,
.contactLink{
	height: 100%;
	font-size: 14px;
	font-weight: 400;
	float: right;
	color: black;
	text-decoration: none;
}

.contactLink:hover {
	color: var(--viamap-green);
}

.contactLink::before {
	padding-right: 5px;
	color: var(--viamap-green);
	font-size: 1.2em;
}

div.outerframe {
	border-top: 5px solid var(--viamap-green);
	height:100%;
}

.intro {
	opacity: 1;
}
.outtro {
	padding-inline: 10%;
	/* position: absolute; */
	flex-wrap: wrap;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	align-content: space-around;
	background-color: var(--viamap-dark);
	width: 100%;
	bottom: 0;
	left: 0;
	padding-block: 20px;
	row-gap: 12px;
}

.fillerText {
	width: 380px;
	line-height: normal;
}

.externalLinks {
	min-width: 260px;
	display: flex;
	justify-content: center;
}

.externalLinks a:hover,
.externalLinks a:active,
.externalLinks a:focus {
	scale: 1.3;
	outline: #fff solid 1px;
}


.externalLinks a {
	transition: 100ms scale linear;
	display: block;
	color: #fff;
	text-align: center;
	font-size: 1.4em;
	border-radius: 2em;
	margin-inline: 1em;
	background-color: var(--viamap-light);
	width: 2em;
	line-height: 2em;
	height: 2em;
}



.active .intro {
	opacity: 0;
	transition: opacity .2s ease-in;
}
.active .outtro {
	opacity: 1;
	transition: opacity 1s ease-out;
}

.addressfield label {
font-weight:400;
text-align:center;
text-transform:uppercase;
font-size: 1.4em;
}

.contacts {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: row-reverse;
	flex-wrap: wrap;
	column-gap: 50px;
}

.autocomplete-container > div {
  position: absolute;
  top: 100%;
  max-height: 50vh;
	max-height: calc(var(--vh, 1vh) * 50);
  left: 10%;
  width: 80%;
  z-index: 9999;
}


.dawa-wrap {
  background-color: rgba(255,255,255,.3);
  padding-block: 0.6em;
}

.autocomplete-container > input {
  text-align: center;
}

.dawa-autocomplete-suggestions {
  z-index: 9999;
	max-height: calc( calc(var(--vh, 1vh) * 100) - 190px);
	overflow: auto;
}

.dawa-autocomplete-suggestions:first-child {
  border-top: solid rgb(218, 218, 218) 1px;
	
}

.dawa-autocomplete-suggestion {
  font-size: 0.9em;
  padding-block: 0.3em;
  padding-left: 1em;
  text-align: left;
  cursor: pointer;
  width: 100%;
  background: white;
  
  border-bottom: solid rgb(218, 218, 218) 1px;
  border-right: solid rgb(218, 218, 218) 1px;
  border-left: solid rgb(218, 218, 218) 1px;
  list-style-type: none;
}

.dawa-autocomplete-suggestion:focus,
.dawa-autocomplete-suggestion:active,
.dawa-autocomplete-suggestion:hover {
  background: rgb(251, 232, 232);
}

.autocomplete-container > div > li {
  position: absolute;
  top: 100%;
  max-height: 50vh;
	max-height: calc(var(--vh, 1vh) * 50);
  left: 10%;
  width: 80%;
}

.autocomplete-container {
  position: relative;
	padding-inline: 1%;
}

header {
	position: sticky;
	top:0px;
	width: 100%;
	background: var(--viamap-light);
	justify-content: center;
	transition: all 0.2s ease-in-out;
	z-index: 500;
}

.active header {
	height: 160px;
	background: rgba(255,255,255, .3);
	transition: all 0.2s ease-in-out;
	overflow: hidden;
}


main {
	background-color: #fff;
	-ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.main::-webkit-scrollbar {
  display: none;
}


	
.wrap_active {
   top: 0%;
}

.distances {
	list-style: none;
   position: relative;
  }
	.to {
	margin-top: 16px;
	font-weight: normal;
	text-align: center;
	text-transform: uppercase;
  }

	.distance {
	line-height: 1.2em;
	justify-content: center;
	overflow: hidden;
	padding: 8px;
	margin: 8px;
	height: 156px;
	width: 144px;
	background: #E3EAE9aa;
	/* box-shadow: 0 .16px 5px rgba(0,0,0,.2); */
	float:left;
	margin: 8px;
	clear:none;
  }
  
	.distance.clickable {
	cursor: pointer;
  }

	.contactLink span {
		float: right;
		text-align: right;
		width: 120px;
	}

	.CContent {
		background-color: unset;
		border: 0px;
	}

	h2 {
	font-size: 15px;
	min-height: 39px;
  }

	.c > ul > li {
		box-shadow: #2021 5px -7px 5px -5px inset;
		background-color: var(--viamap-light);
		/* border-radius: 5px 5px 0px 0px; */
	}
	
	.c > ul > li > button {
		color: #fff;
		font-size: 1em;
		box-shadow: #fff3 -5px 5px 5px -5px inset;
		padding: 0.5em 0.7em 0.2em;
		font-weight: lighter;
		border-radius: 0px !important;
	}

	.c :last-child > li {
		border-radius: 0px 5px 0px 0px;
	}

	.c .tab-content {
		background: #fff ;
	}

	p.name {
		font-size: 13px;
		max-height: 39px;
		overflow: hidden;
	}

	.form-check-input:checked {
		border-color: var(--viamap-green);
		background-color: var(--viamap-green);
	}

	.form-check-input:disabled {
		opacity: 0.7;
	}

	.form-switch .form-check-input {
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4' fill='rgba(255, 255, 255, 1)'/%3e%3c/svg%3e");
	}

	.form-switch .form-check-input:checked {
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4' fill='rgba(255, 255, 255, 1)'/%3e%3c/svg%3e");
	}

	.form-check-input {
		background-color: var(--viamap-dark);
		opacity: 0.9;

	}

	.CSa {
		background-color: #fff;
	}

	.CCFooter {
		color: white;
		background-color: var(--viamap-green);
		box-shadow: #2024 0px 7px 5px -5px inset;
	}

footer {
	text-align: center;
	line-height: 48px;
	color: rgba(0,0,0, .3);
}
footer a {
	color: rgba(0,0,0, .5);
}
.active footer {
	display: block;
}

.CCFooter .btn-light {
	transition: opacity linear 100ms;
}

.CCFooter .btn-light:hover {
	opacity: 0.7;
}

.Cmodal-body {
	padding: unset;
}

.Cmodal .infoBox {
	margin: 1.1em 1em 1em 1.5em;
}

.Ctab-content > ul {
	padding-left: 1rem;
	color: #686868;
	padding-bottom: 0.5rem;
}

.CSa:first-child {
	padding-top: 3em;
}

.Ctab-content div {
	margin-top: 0.5rem;
	display: block;
	width: 100%;
}

.Ctab-content {
	padding: 1rem;
	font-size: 0.9em;
}

.wrapper {
	padding: 30px 0px;
	max-width: 800px;
	margin: 0 auto;
	width: 90%;
	min-height: calc(100vh - 374.6px);
	min-height: calc( calc(var(--vh, 1vh) * 100) - 374.6px);
	text-align: center;
	/* overflow:hidden; */
/*
   position: relative;
   top: 30%;
   transform: translateY(-30%);

*/	
	transition: all 0.6s ease-in-out;

}



.wrapWidthWrapper {
	width: 100%;
	position: sticky;
	top: 83px;
	height: 1.3em;
	background-color: #b6c9c6;
	z-index: 1;
}

.wrapWidth {
	position: relative;
	max-width: 800px;
	margin: 0 auto; 	
}

.wrapWidth > div {
	display: flex;
	justify-content: space-between;
}

@media screen and (max-width:888px){

	.wrapper, .wrapWidth {
		max-width: unset;
		width: 640px;
	}

}

@media screen and (max-width:639px){
	#dawa-autocomplete-input{
		font-size: 0.8em;
		height: 51px;
	}
	.dawa-autocomplete-suggestions {
		font-size: 0.8em;
	}

	.wrapWidthWrapper {
		top:76px;
	}

	.GreenBar {
		padding: 0px 5% ;
	}

	.wrapper, .wrapWidth {
		max-width: unset;
		width: 480px;
	}

	.SortButtons {
		top: 198px;
	}
}

@media screen and (max-width: 479px){
	#dawa-autocomplete-input{
		font-size: 0.6em;
		height: 51px;
	}
	.dawa-autocomplete-suggestions {
		font-size: 0.6em;
	}

	
	.wrapWidthWrapper {
		top:76px;
	}

	.contactLink span{
		display: none;
	}

	.versionNr {
		display: none;
	}

	.contacts {
		padding-right: 20px;
	}

	.GreenBar {
		padding: 0;
	}

	.wrapper, .wrapWidth {
		max-width: unset;
		width: 320px;
	}
}

@media screen and (max-width: 319px){
	.wrapper, .wrapWidth {
/*		margin: 0 calc( ( 100vw - (144px * 4)) * 0.25 ) !important;*/
		max-width: unset;
		width: 160px;
	}
}

@keyframes enter {
	from {
	opacity: 0;
	transform: translate(0, -48px) scale(1.05);
  }
	to {
	opacity: 1;
	transform: translate(0, 0) scale(1);
  }
}
@-webkit-keyframes enter {
	from {
	opacity: 0;
	transform: translate(0, -48px) scale(1.05);
  }
	to {
	opacity: 1;
	transform: translate(0, 0) scale(1);
  }
}

.map-frame {
	position: fixed ;
	border:10px solid #aaa;
	border-color:#666;
	border-radius:5px;
	transition: opacity linear 200ms;
/*	padding:10px;*/
/*	color:#fff;*/
/*	display: block;*/
/*	display: inline-block;*/
	z-index: 800;
/*	overflow:hidden;*/
}

.map-frameAni {
	transition: left linear 100ms, top linear 100ms, width linear 100ms, height linear 100ms;
}

.map-frame .map-container {
	position: relative;
	width:100%;
	height: 100%;
}

/*.map-container {
 	display: block;
	height:300px;
	width:300px;
}
*/
a.button {
/*	opacity: 0.6;*/
	cursor: pointer;
	height:45px;
	width:30px ;
	background-size: 24px;
	background-position: center;
	background-repeat: no-repeat;
}


a.button {
background-position:center;
background-repeat:no-repeat;
background-size:24px;
cursor:pointer;
height:45px;
position:relative;
top:-38px;
width:30px;
}

.Exclusions {
	position: absolute;
	left: 10px;
	margin-top: 10px;
	max-height: 100%;
	overflow-y: auto;
	color: white;
}

li.Filter {
	border: white solid 1px;
	display: flex;
	justify-content: space-between;
	background-color: var(--viamap-green);
	text-decoration-style: none;
	gap: 10px;
}

button.close_button {
	cursor: pointer;
	position:absolute;
	background: none;
	font-size: 2em;
	background: #666;
	color: #FA9B2E;
  border: 0;
	border-radius: 5px;
	float: right;
}

button.close_button:hover {
	color: var(--viamap-green);
}

.map-frame .close_button {
	transition: color 0.3s linear, opacity 0.3s linear;
	position:absolute;
	opacity: 0;
	padding: 0px 3px;
	top: -8px;
	right: -7px;
	z-index: 1000;
}

.icon {
background-size: 32px;
background-position: center;
background-repeat: no-repeat;
width: 100%;
height: 40px;
}

.icon2btm {
/*	border: 1px solid red;*/
	position:absolute;
	bottom:-2px;
	width: 100%;
}

.icon2btm > img {
	background: #fff;
	object-fit: cover;
	object-position: 0px 0px;
	border-radius: 100px;
	width: 40px;
	height: 40px;
}

.icon2btms {
/*	border: 1px solid red;*/
	position:absolute;
	bottom:8px;
	width: 100%;
}

.icon2btms > img {
	background: #fff;
	object-fit: cover;
	object-position: 0px 0px;
	border-radius: 100px;
	width: 60px;
	height: 60px;
}

/*********************/

nav.menu-ui {
	background: #fff;
	position: absolute;
	top: 50px;
	right: 20px;
	z-index: 1000;
	border-radius: 3px;
	border: 1px solid rgba(0,0,0,0.4);
	
	background-color: white;
	pointer-events: auto !important;
	border-radius:4px;
}

.menu-ui a.tselbtn {
	background-size: 66%;
	background-position: center;
	background-repeat: no-repeat;
}

.menu-ui a {
	width: 32px;
	height: 32px;
	font-size: 13px;
	color: #404040;
	display: block;
	margin: 0px;
	padding: 5px;
	text-decoration: none;
	border-bottom: 1px solid rgba(0,0,0,0.25);
	text-align: center;
	
	-moz-transition: background-size 0.1s ease-in-out;
	-o-transition: background-size 0.1s ease-in-out;
	-webkit-transition: background-size 0.1s ease-in-out;
	transition: background-size 0.1s ease-in-out;
}

.menu-ui a:first-child {
	border-radius: 3px 3px 0 0;
}

.menu-ui a:last-child {
	border: none;
	border-radius: 0 0 3px 3px;
}

.mapboxgl-control-container .menu-ui a:hover {
	background-color: #f8f8f8;
	background-size: 87%;
	color: #404040;
}

.Cmodal {
	max-width: 560px;
}

.CookieMiniBanner {
	cursor: pointer;
	font-size: 0.85em;
	background-color: var(--viamap-light);
	position: fixed;
	padding: 0.3em;
	bottom: 0px;
	left: 0px;
	border: white 2px solid;
	transition: border 300ms linear, opacity 300ms linear;
	z-index: 200;
}

.CookieMiniBanner:active,
.CookieMiniBanner:hover {
	border: yellow 2px solid;
	opacity: 0.8;
}

.clickC {
	color: #0a58ca;
	cursor: pointer;
}

.clickC:hover,
.clickC:active {
	color: #3074a4;
}

.menu-ui a.active {
	background-color: #3887BE;
	color: #FFF;
}
.menu-ui a.active:hover {
	background-color: #3074a4;
}

.mapboxgl-control-couttrontainer div.ContInfo {
    position: absolute;
    left: 10px;
    bottom: 45px;
    width: 170px;
    background: rgba(0,0,0,0.5);
    color: rgb(255,255,255);
    font-size: 12px;
    border-radius: 3px;
    padding: 5px 10px;
}

.mapboxgl-control-container div.ContInfo span {
    display: block;
    width: 100%;
    line-height: 18px;
}


/*********************/
.mapboxgl-control-container a.togglebutton {
  background: #fff;
  position: absolute;
  z-index: 1001;
  top: 10px;
  right: 70px;

	font-size: 13px;
	color: #404040;
	display: block;
	padding: 0;
	padding: 5px 10px;
	text-decoration: none;
	border: 1px solid rgba(0, 0, 0, 0.25);;
	text-align: center;
	background-color: white;
	pointer-events: auto !important;
	border-radius:4px;
}

.mapTitle {
	position: absolute;
	top: 10px;
	left: 50px;
	z-index : 9999;
	color: white;
	background: #666a;
	font-size: 16px;
	padding: 5px;
	border-radius: 5px;
	max-width: 70%;
}

.mapTitle > .Type {
	text-decoration: underline;
}

.mapTitle > .Name {
	padding-left: 1ch;
}

.mapRouteInfo {
	display: flex;
	flex-direction: column;
	position: absolute;
	bottom: 50px;
	z-index: 9999;
	left: 10px;
	background-color: #666a;
	font-size: 12px;
	padding: 5px;
	border-radius: 5px;
	color: white;
	transition: background-color linear 150ms;
}

.mapRIAni {
	background-color: yellowgreen;
}


.mapTransType {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 50px;
	z-index: 9999;
	right: 10px;
	font-size: 12px;
	border-radius: 4px;
	background: #fff;
	box-shadow: 0 0 0 2px rgba(0,0,0,.1);
	overflow: hidden;
}

.mapTransType div {
	cursor: pointer;
	width: 29px;
	height: 29px;
	padding: 3px;
	user-select: none;
}

.spacer {
	pointer-events: none;
	background-color: #aaa;
	padding: 0px !important;
	height: 3px !important;
}

.orthoBut {
	width: 100%;
	height: 100%;
	font-size: 1.8em;
	line-height: 1.2em;
}

.transActive {
	background-color: var(--viamap-green);
	pointer-events: none;
}

.mapTransType div:hover {
	background-color: rgba(0,0,0,.05);
}

.mapTransType div + div { 
	border-top: 1px solid #ddd;
}

.mapboxgl-control-container a:hover {
  background-color: #f8f8f8;
  color: #404040;
}

.mapboxgl-control-container a.active {
  background-color: #3887be;
  color: #ffffff;
}

.mapboxgl-control-container a.togglebutton.active:hover {
  background-color: #3074a4;
}

.distance.fake {
	background-color: #e0e397;
}

.distance.fake.NoAccess.clickable {
	transition: background 0.1s linear;
}

.distance.fake.NoAccess.false.clickable:hover {
	background-color: #b19696;
}
.distance.fake.NoAccess.Disabled.clickable:hover {
	background-color: #a3b196;
	opacity: 0.6;
}

.distance.fake.Disabled:not(.false) {
	opacity: 0.5;
	box-shadow: inset 0px 0px 1px 3px #fff6;
	transform: scale(0.9);
}

.distance.fake.NoAccess {
	background-color: var(--viamap-light);
}

.TopInfo {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-inline: 7px;
}

.TopInfo > .btn {
	margin-left: 2em;
}

.TopInfo > div {
	text-align: left;
}

.distance.fake {
	height: 100px;
	width: 100px;
	margin: 7px;
}

.distance.fake .type {
  font-size: 11px;
}

.distance.fake:hover .aktionKeeper {
	opacity: 1;
}

.aktionKeeper {
	cursor: pointer;
	opacity: 0;
	position: absolute;
	top: 60px;
	font-size: 3em;
	left: 40px;
	z-index: 1;
	background: white;
	border-radius: 100%;
	transition: opacity 0.1s linear;
}

.btn-primary {
	--bs-btn-color: #fff;
	--bs-btn-bg: orange;
	--bs-btn-border-color: orange;
	--bs-btn-hover-color: #fff;
	--bs-btn-hover-bg: rgb(206, 134, 0);
	--bs-btn-hover-border-color: #ca670a;
	--bs-btn-focus-shadow-rgb: 253, 165, 49;
	--bs-btn-active-color: #fff;
	--bs-btn-active-bg: #ca670a;
	--bs-btn-active-border-color: #be700a;
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: #fff;
	--bs-btn-disabled-bg: orange;
	--bs-btn-disabled-border-color: orange;
}

.filterButton {
	width: max-content;
	cursor: pointer;
	transition: top 0.2s linear, opacity 0.2s linear;
	/* transform: translateX(-100%); */
	z-index: 750;
	line-height: 20px;
	text-decoration:underline;
}

.filterButton:hover {
	opacity: 0.5 !important;
}